import { ResponseI } from '@/interfaces/response.interface'
import { UserI } from '@/interfaces/user.interface'
import axios from '@/services/base.service'
import { AxiosResponse } from 'axios'

const resource = 'users'

// UPDATE A CARD
export const updateUser = async (
  user: any
): Promise<AxiosResponse<ResponseI<UserI>>> => {
  return await axios.put(resource, user)
}

export const getMyData = async (): Promise<AxiosResponse<any>> => {
  return await axios.get('/me')
}
