
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getMyPetitions, updatePetition } from '@/services/seller.service'
import { idoDateToDateAndHours } from '@/helpers/date'
import { ErrorMessages } from '@/helpers/constants'
import { getMyData } from '@/services/user.service'
import { UserI } from '@/interfaces/user.interface'
import _ from 'lodash'

@Component({
  components: {
    SellerHeader,
  },
})
export default class MyPetitionsList extends Vue {
  petitions: [] = []
  user: UserI
  public skeletonCount = [0, 1, 2, 3, 4]
  public loading = false
  isEmpty = false

  async getPetitions() {
    try {
      this.loading = true
      const data = await getMyPetitions()
      const petitions = data.data.data

      this.isEmpty = !petitions.length ? true : false
      this.petitions = await _.groupBy(
        petitions,
        petition => petition.event.name
      )

      this.loading = false
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  date(isoDate: string) {
    return idoDateToDateAndHours(isoDate)
  }

  mounted() {
    this.getPetitions()
    this.getMyData()
  }

  async getMyData() {
    try {
      const { data } = await getMyData()
      this.user = data
    } catch (e) {
      console.error(e)
    }
  }
}
